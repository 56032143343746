<div class="wrapper">
    <div class="limiter">
        <div class="container-login100">
            <div class="wrap-login100 p-t-35 p-b-10">
                <div class="login100-form validate-form">
                    <span *ngIf="userLog.fullName" class="login100-form-title p-b-20">
                     Confess for {{userLog.fullName?userLog.fullName:''}}
                </span>


                    <div class="wrap-input100 validate-input m-t-30 m-b-30" data-validate="Enter message">
                        <textarea class="form-control custom input100" rows="4" [(ngModel)]="confessMsg"></textarea>
                        <span class="focus-input100" data-placeholder="Enter your message"></span>
                    </div>
                    <div class="wrap-input100 validate-input m-t-30 m-b-20" data-validate="Enter riddle">
                        <textarea class="form-control custom input100" rows="4" [(ngModel)]="confessRid"></textarea>
                        <span class="focus-input100" data-placeholder="Enter a riddle telling about yourself"></span>
                    </div>
                    <div class="loader" [ngStyle]="{'display':display}">
                        <img src=".././assets/images/Spin-1s-200px.gif">
                    </div>

                    <div class="container-login100-form-btn">
                        <button class="login100-form-btn" id="submitConfess" (click)="submitConfession()">
                        Confess
                    </button>
                    </div>
                    <div class="link-text p-t-20">
                        <p>Create your own <a href="https://say4u.invincix.net">account</a> & see what people think about youhere</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>