import { Component, OnInit } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment  } from '@env';
import * as $  from 'jquery';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {
  showModal : boolean;
  isCopied : boolean;
  userName = '';
  userEmail = '';
  mobileNumber ;
  pattern = "^((\\+91-?)|0)?[0-9]{10}$";
  result;
  resData : any;
  confessUrl = '';
  userId =  '';
  url = environment.api_url + '/api/users/submituser';
  

  constructor(private http: HttpClient){

  }
  sendData()  {
    this.showModal = true;
    if(!(this.userName == " " && this.userEmail == " " && this.mobileNumber == " ")){
      const mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
      if(mobNumberPattern.match(this.mobileNumber.value)) {
      
        let userData = {
          'fullName':  this.userName,
          'email': this.userEmail,
          'mobile_number': this.mobileNumber
        }
       
       this.http.post(this.url, userData).subscribe(
        data => { 
           this.resData = data;
           this.confessUrl = this.resData['confessUrl'];
           this.userId = this.resData['uniqueCode'];
          
        },
          err => {console.log(err)},
        
       )
        
      }
     
      }
    }
    closeModal() {
      this.showModal = false;

    }

   

   
  
  ngOnInit(): void {
    $(`.input100`).each(function() {
      $(this).on('blur', function() {
          if ($(this).val().trim() != "") {
              $(this).addClass(`has-val`);
          } else {
              $(this).removeClass(`has-val`);
          }
      })
  });
  }

}
