import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateUserComponent } from './create-user/create-user.component';
import { SubmitConfessionComponent } from './submit-confession/submit-confession.component';
import { ListConfessionComponent } from './list-confession/list-confession.component';

const routes: Routes = [

  { path: '', component: CreateUserComponent },
  { path: 'confess', component: SubmitConfessionComponent },
  { path: 'getconfessions', component: ListConfessionComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
