<div class="wrapper">
    <div class="limiter">
        <div class="container-login100">
            <div class="wrap-login100 p-t-85 p-b-20">
                <div class="login100-form validate-form">
                    <span class="login100-form-title p-b-30">
                    Say4U
                </span>

                    <!-- <div class="avatar-upload">
                        <div class="avatar-edit">
                            <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" />
                            <label for="imageUpload"></label>
                        </div>
                        <div class="avatar-preview">
                            <div id="imagePreview" style="background-image: url('../assets/images/ava.png');">
                            </div>
                        </div>
                    </div> -->


                    <div class="wrap-input100 validate-input m-t-30 m-b-45" data-validate="Enter username">
                        <input class="input100 form-control" [(ngModel)]="userName" type="text" name="username" #name="ngModel" required>
                        <span class="focus-input100" data-placeholder="Name"></span>
                    </div>
                    <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
                        <div *ngIf="name.errors.required">
                            Name is required.
                        </div>
                    </div>
                    <div class="wrap-input100 validate-input m-b-30" data-validate="Enter email">
                        <label class="info-text">Gmail Id is preferrable</label>
                        <input class="input100 form-control" [(ngModel)]="userEmail" type="email" name="email" #email="ngModel" required email>
                        <span class="focus-input100" data-placeholder="Email"></span>
                    </div>
                    <div *ngIf="email.invalid && (name.dirty || name.touched)" class="alert alert-danger">

                        <div *ngIf="email.errors.required">Email is required</div>
                        <div *ngIf="email.errors.email">Email must be a valid email address</div>

                    </div>
                    <div class="wrap-input100 validate-input m-b-30" data-validate="Enter number">
                        <input class="input100" [(ngModel)]="mobileNumber" [(pattern)]="pattern" type="text" name="number" #number="ngModel" maxlength="12" minlength="10" required>
                        <span class="focus-input100" data-placeholder="Mobile Number"></span>
                    </div>
                    <div *ngIf="number.invalid && (number.dirty || number.touched)" class="alert alert-danger">
                        <div *ngIf="number.errors.required">Mobile Number is required</div>
                        <div *ngIf="number.errors.minlength">
                            Mobile number must be 10 characters long.
                        </div>
                        <div *ngIf="number.errors?.pattern">
                            Mobile number not valid.
                        </div>
                        <div *ngIf="number.errors.maxlength">
                            Mobile number must be 10 characters long.
                        </div>
                        <div *ngIf="number.errors.number">Mobile number must be a valid mobile number</div>
                    </div>

                    <div class="container-login100-form-btn">
                        <button class="login100-form-btn" (click)="sendData()">
                        Submit
                    </button>
                    </div>
                    <div class="link-text p-t-20">
                        <p>See your confessions <a href="https://say4u.invincix.net/getconfessions">here</a></p>
                    </div>
                </div>
                <div class="backdrop" [style.display]="showModal ? 'block' : 'none'">
                    <div class="modal fade" [style.display]="showModal ? 'block' : 'none'" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" id="exampleModal">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Your Unique code to view confessions is<span>{{userId}}</span></h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
                            <span aria-hidden="true">&times;</span>
                          </button>
                                </div>
                                <div class="modal-body">
                                    <P>Copy the url and share it to get confessions about you</P>
                                    <div id="inviteCode" class="invite-page">
                                        <input id="link" [(ngModel)]="confessUrl" #copyTarget>
                                        <div id="copy">
                                            <i class="fa fa-clipboard" aria-hidden="true" (cbOnSuccess)="isCopied = true" [ngxClipboard]="copyTarget"></i>
                                            <div *ngIf="isCopied"><span class="copy">Copied</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">

                                    <button type="button" class="btn btn-secondary custom-button" data-dismiss="modal" (click)="closeModal()">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>