import { Component, OnInit } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {ActivatedRoute} from "@angular/router";
import { environment  } from '@env';
import * as $  from 'jquery';
import Swal from 'sweetalert2'
import { Options } from 'selenium-webdriver/chrome';
@Component({
  selector: 'app-submit-confession',
  templateUrl: './submit-confession.component.html',
  styleUrls: ['./submit-confession.component.css']
})
export class SubmitConfessionComponent implements OnInit {
  name: any;
  display = 'none';
  error: any;
  code : Number;
  userLog : any = undefined;
  confessMsg = '';
  confessRid = '';
  analysis : any;
  url = environment.api_url +'/api/confessions/submitconfession';
  _url= environment.api_url + '/api/users/userById';
  constructor(private route: ActivatedRoute,private http: HttpClient) { 
    
  }
  submitConfession() {
   
    this.display = 'block';

    let userConfession = {
      'message': this.confessMsg,
      'riddle': this.confessRid,
      'uniqueCode': this.code
    }
    console.log(userConfession);
    this.http.post(this.url, userConfession).subscribe(
      data => { 
         
        this.analysis = data;
        // console.log(this.analysis);
        let message1 ="";
        let message2 ="";
      if(this.analysis == 'positive'){
          message1 = "Thank You!";
          message2 = "You have really positive views";
      }
      else if(this.analysis == 'neutral'){
          message1 = "Umm!";
          message2 = "Its okay to have normal views sometimes!";
      }
      else if(this.analysis == 'negative'){
          message1 = "That's bad!";
          message2 = "May the person improves after your gloomy feedback!"
      }
      this.display = 'none';
      Swal.fire({
        title: message1,
        text: message2,
        icon: "success",
       
    });    
      },
        err => {
          this.error = err;
          console.log(err)
        },
      
     )
     
 
  }
 
  getUserName() {

    let userId = {
      'userId' : this.code
    }
    
    this.http.post(this._url,userId).subscribe(
      data => {
          this.userLog = data;
          // console.log("*******")
          // console.log(this.userLog);
          // console.log(data);
      }
      ) 
     }
  ngOnInit() {

    
    this.route.queryParamMap.subscribe(queryParams => {
    this.name = queryParams;
    this.code = this.name['params']['code'];
    // console.log(this.code);
    this.getUserName();
  });
  
 
  $(`.input100`).each(function() {
    $(this).on('blur', function() {
        if ($(this).val().trim() != "") {
            $(this).addClass(`has-val`);
        } else {
            $(this).removeClass(`has-val`);
        }
    })
});

}
}
