import { Component, OnInit } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment  } from '@env';
import { Observable } from 'rxjs';
import  Swal from 'sweetalert2';
@Component({
  selector: 'app-list-confession',
  templateUrl: './list-confession.component.html',
  styleUrls: ['./list-confession.component.css']
})
export class ListConfessionComponent implements OnInit {
  polarity: any;
  error: any;
  confessions: any;
  url = environment.api_url + '/api/confessions/getconfessions';
  constructor(private http: HttpClient) { }
  
   fetchConfessions(code : any) {
     let uniqueCode = {
       'uniqueCode': code
     }
     this.http.post(this.url,uniqueCode).subscribe(resData => {
            this.confessions = resData;
            
     },
     err => {
      this.error = err;
      console.log(err)
    },
     )
    
   } 
   
  ngOnInit() {
    
    Swal.fire({
      title: 'Enter your Unique Code',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      inputPlaceholder: "Enter your Unique Code",
      showCancelButton: false,
      confirmButtonText: 'Get your confessions',
      showLoaderOnConfirm: true,
      
      allowOutsideClick: () => !Swal.isLoading()
    }).then((text) => {
      if (text.value) {
      
         this.fetchConfessions(text.value);
      }
      else if(text.value === " ") {
          Swal.fire("You need to enter your Unique Code");
          return false;
        }
        else if(!this.error) {
          Swal.fire("Invalid Id");
        }
      
    })

    // this.fetchConfessions();
  }
   
}
